
//util api to handle the browser address/location stuff

(function($,locationObj){
    $.tempoAddress = (function(){
        var location = $(locationObj)[0];

        var value = function(hash){
            if(hash){
                location.hash = hash;
            }
            return location.hash;
        };

        var change = function(eventHandler){
            if(eventHandler){
                $(window).on('hashchange',eventHandler);
            }
        };

        var queryString = function(query){
            if(query){
                location.hash.replace(/\?(.*)/,query);
            }
        };

        var path = function(){
            if(location.hash && location.hash.indexOf("?") >= 0){
                var path = location.hash.replace(/.*\#(\/.*)/,'$1'); //parse the route and return
                return path;
            }
        };

        var queryParameter = function(param){
             if(location.hash && location.hash.indexOf("?") >= 0 ){
                    var params = location.hash.split("\?")[1].split("&");
                    var paramObj = {};
                    var temp;
                    for(var i=0; i<params.length; ++i){
                        temp = params[i].split("=");
                        paramObj[temp[0]] = temp[1];
                    }
                    return paramObj[param];
             }
        };
        return {
            "parameter":queryParameter,
            "value":value,
            "change":change,
            "queryString":queryString,
            "path":path
        }
    })();
})(jQuery,location);