if (typeof info.version === "undefined"){
	info.version = {};
}

info.version.ui= "16.1.2";
info.version.api = "unknown";
/* 
info.version.api should be updated when the first auth call is made to the engine
info.version.buildDate will be created by the build system at the time the repo is built
*/


